export const onServiceWorkerUpdateReady = () => {
  if (!window?.location) return;

  window.location.reload();
};

export const onClientEntry = () => {
  const script = document.createElement('script');
  script.src =
    'https://platform.illow.io/banner.js?siteId=25438428-b0e5-41a5-b873-b234ae0ad2db';
  script.async = true;
  document.head.appendChild(script);
};
